import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React from 'react'
import MetaImage from '../../assets/images/meta/meta-blueoptima.png'
import AllWorkIcon from '../../assets/images/product/blurb-icons/all-work.png'
import GearIcon from '../../assets/images/product/blurb-icons/gear.png'
import LockIcon from '../../assets/images/product/blurb-icons/lock.png'
import CustomerLogos from '../../components/CustomerLogos'
import FAQ from '../../components/EnterpriseFAQ'
import Layout from '../../components/Layout'
import CTABlock from '../../components/blocks/CTABlock'
import ColumnsBlock from '../../components/blocks/ColumnsBlock'
import HeroBlock from '../../components/blocks/HeroBlock'
import LeadBlock from '../../components/blocks/LeadBlock'
import QuoteBlock, { quotes } from '../../components/blocks/QuoteBlock'
import { FeaturedBlogArticles } from '../../components/changelog/FeaturedBlogArticles'
import Box from '../../components/primitives/Box'
import Blurb from '../../components/system/Blurb'
import { responsiveScale } from '../../styles/helpers'
import QuoteMosaicBlock from '../../components/blocks/QuoteMosaicBlock'

export const query = graphql`
  {
    backgroundImage: file(
      relativePath: { eq: "images/product/overview/hero-bg.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, quality: 99)
      }
    }
    mosaicLeft: file(
      relativePath: { eq: "images/mosaic/report-cost-cap-mosaic.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    mosaicRight: file(
      relativePath: { eq: "images/mosaic/transparent-surveys.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    mosaicLeft2: file(relativePath: { eq: "images/mosaic/data-cloud.png" }) {
      childImageSharp {
        gatsbyImageData(width: 680)
      }
    }
    mosaicRight2: file(relativePath: { eq: "images/mosaic/metrics.png" }) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    overview: file(relativePath: { eq: "images/enterprise/overview.png" }) {
      childImageSharp {
        gatsbyImageData(width: 670)
      }
    }
    integrations: file(
      relativePath: { eq: "images/enterprise/integrations.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 670)
      }
    }
    organizationalInsights: file(
      relativePath: {
        eq: "images/product/overview/organizational-insights.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 632)
      }
    }
    deploymentInsights: file(
      relativePath: { eq: "images/product/overview/deployment-insights.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 632)
      }
    }
    pinpointIssues: file(
      relativePath: { eq: "images/product/overview/pinpoint-issues.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 300)
      }
    }
  }
`

const BlueOptimaPage = () => {
  const data = useStaticQuery(query)

  return (
    <Layout
      title="BlueOptima vs. Swarmia"
      variant="dark"
      isNew
      description="BlueOptima is the right fit for large corporations wanting to surveil developer productivity with endless number of quantitative data points. However, if you’re seeking for a tool that tracks developer productivity holistically and offers the whole engineering organization with actionable tools to increase efficiency, Swarmia is likely a better fit for your needs."
      metaImage={MetaImage}
    >
      <HeroBlock
        title={<>BlueOptima vs. Swarmia</>}
        content="BlueOptima suits large corporations wanting to monitor developer productivity with quantitative data. If you want to track developer productivity holistically and offer the whole engineering organization tools to increase efficiency, Swarmia is likely a better fit for your needs."
        backgroundImage={getImage(data.backgroundImage)!}
      />
      <Box
        // This is needed for the styles to work
        className="main-body-old-styles"
      >
        <CustomerLogos />
      </Box>
      <LeadBlock
        heading="Why modern engineering leaders choose Swarmia over BlueOptima"
        content="Following quantitative metrics only can lead your organization down the wrong track. Swarmia provides companies with comprehensive, actionable insights that help them ship faster."
      />
      <QuoteMosaicBlock
        title="A combination of quantitative and qualitative data"
        content={
          <>
            Swarmia combines science-backed quantitative developer metrics with
            qualitative data from surveys to give engineering leaders and
            managers a 360-degree view of their engineering organization.
            <br />
            <br />
            BlueOptima offers solely quantitative developer analytics and
            metrics, including vanity metrics like lines of code. This can lead
            to developers gaming the system and managers making the wrong
            decisions.
          </>
        }
        image={getImage(data.mosaicRight)!}
        imageAlign="right"
        quote={quotes.dave}
      />
      <QuoteMosaicBlock
        title="Enterprise-level features for teams big and small"
        content={
          <>
            BlueOptima is built for directors in large corporations to monitor
            productivity, especially in banking.
            <br />
            <br />
            Swarmia is adopted by modern engineering organizations from startups
            to enterprises, offering features that support everyone from
            developers to the leadership.
            <br />
            <br />
            Swarmia offers the whole engineering organization tools to track
            progress, gain actionable insights, and reduce bottlenecks
          </>
        }
        image={getImage(data.mosaicLeft)!}
        quote={quotes.alex}
      />
      <QuoteMosaicBlock
        title="Get actionable insights in days, not months"
        content={
          <>
            Unlike BlueOptima, which takes time to set up, Swarmia combines the
            best of both worlds: enterprise-grade features with flexible
            self-service configuration that only takes hours.
            <br />
            <br />
            You will get access to Swarmia’s intuitive and granular dashboards
            in days, not months.
          </>
        }
        image={getImage(data.mosaicRight2)!}
        imageAlign="right"
        quote={quotes.pau}
      />
      <QuoteBlock person="maryanna" />
      <LeadBlock
        heading="Works for your developers, not against them"
        content="While BlueOptima provides solely quantitative metrics, Swarmia provides you with actionable insights and tools helping your organization ship faster."
      />
      <ColumnsBlock
        paddingTop={responsiveScale(48)}
        paddingBottom={responsiveScale(48)}
        columns={[
          <Blurb
            key="1"
            title="Built to scale"
            text="From team hierarchies to role-based access and export APIs, Swarmia fits the needs of large organizations."
            isLarge
            image={getImage(data.overview)!}
          />,
          <Blurb
            key="2"
            title="Easy to integrate"
            text="Swarmia works with your internal systems as well as the the tools your engineering teams already use."
            isLarge
            image={getImage(data.integrations)!}
          />,
        ]}
      />
      <ColumnsBlock
        paddingBottom={responsiveScale(64)}
        columns={[
          <Blurb
            key="1"
            icon={GearIcon}
            title="Flexible set up and configuration"
            text="Setting up Swarmia is easy. You can either do it yourself or get support from our customer success team — at no extra cost."
          />,
          <Blurb
            key="2"
            icon={AllWorkIcon}
            title="Roll out at your own pace"
            text="Your dedicated customer success manager will work with your team to tailor onboarding and rollout."
          />,
          <Blurb
            key="3"
            icon={LockIcon}
            title="Designed security-first"
            text="Swarmia is SOC 2 Type 2 and GDPR compliant. We perform thorough security audits twice a year."
          />,
        ]}
      />
      <Box
        // This is needed for the FAQ styles to work
        className="main-body-old-styles"
      >
        <FAQ />
      </Box>
      <Box paddingBottom={responsiveScale(64)}>
        <CTABlock
          title="Ready to start combining developer feedback with healthy engineering metrics?"
          secondaryButton={null}
        />
        <FeaturedBlogArticles
          heading="More from the swarmia blog"
          articles={[
            '/blog/developer-survey-questions/',
            '/blog/minimizing-noise-and-bias-in-developer-surveys/',
            '/blog/measuring-software-development-productivity/',
          ]}
          newStyles
        />
      </Box>
    </Layout>
  )
}

export default BlueOptimaPage
